module.exports = {
  sentryDSN: 'https://8d73554f6bed4ba8b1e58daa2927a449@sentry.io/1337470',
  sentryReleasePrefix: 'defaults@',
  apiEndpoint: 'https://api.staging.shootsta.com/',
  serviceEndpoint: 'https://service-defaults.id-staging.shootsta.com/',
  subscriptionsEndpoint: 'wss://service-defaults.id-staging.shootsta.com/subscriptions',
  ssoApiEndpoint: 'https://sso-staging.shootsta.com/saml',
  shootstaDomainSegments: 'develop,staging,app,0c7d217c-7ffa-4c88-a893-196e701f3c65',
  skipNotifications: 'false',
  defaultReturnToSuccessEndpoint: 'https://staging.shootsta.com',
  basicWorkspaceUrl: 'https://staging.shootsta.com',
  socialGoogleClientId: '693034939601-auh968kjd49iei7u3hn005mcitnqh3va.apps.googleusercontent.com',
  socialLinkedInClientId: '86twx8izjl20oc',
  socialAppleClientId: 'com.shootsta.platform',
  recaptchaSiteKey: '6Lf0SXcmAAAAAGY1CURfdpEGvbCIuj5cIxdswT_U'
};
